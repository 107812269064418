import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useOurProcess from 'content-queries/mx/new/our-process'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { HowItWorks, MainContent } from 'mx/components/new'
import Layout from 'mx/layout'

const OurProcessPage = ({ path }: { path: string }) => {
  const { data } = useOurProcess()
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Process',
    })
  }, [])

  const [
    {
      node: {
        sectionModules: [hero, howItWorks, expectations, banner],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <MainContent header data={hero} />
      <HowItWorks data={howItWorks} classname={tw`mt-4`} />
      <HowItWorks
        dark
        data={expectations}
        classname={tw`mt-4 !bg-brown-medium`}
      />
      <MainContent data={banner} inline classname={tw`my-20 lg:my-32`} />
    </Layout>
  )
}

export default OurProcessPage

export const Head = () => {
  const { data } = useOurProcess()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/process"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
